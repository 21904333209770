import { gql } from '@apollo/client'
import { ARTICLE_FRAGMENT, PODCAST_EPISODE_FRAGMENT, PODCAST_FRAGMENT } from './fragments'

export const GetArticlesByIds = gql`
query GetArticlesByIdsQuery($ids: [String]!, $skip: Int = 0, $limit: Int = 10, $locale: String = "en-US") {
  articleCollection(
    order: [sys_id_ASC]
    limit: $limit
    skip: $skip
    where: {sys: {id_in: $ids}}
  ) {
    total
    skip
    limit
    items {
      __typename
      sys {
        id
      }
      title
      slug
      path
      locale
      heroImage {
        imageFile {
          url
        }
        altText
      }
      thumbnail {
        url
        description(locale: $locale)
      }
      externalTagCollection(limit: 2, locale: $locale) {
        items {
          sys {
            id
          }
          title
          slug
        }
      }
    }
  }
}
`

export const GetTrendingArticles = gql`
  query GetTrendingArticles(
    $locale: String = "en-US"
    $limit: Int = 10
    $skip: Int = 0
  ) {
    trending(locale: $locale, limit: $limit, skip: $skip) {
      total
      limit
      skip
      items {
        url
        id
        title
        heroImage {
          imageFile {
            url
          }
          altText
        }
        locale
        thumbnail {
          url
        }
        preview
        path
        slug
        tags
        enhancedTags {
          slug
          title
          id
        }
      }
    }
  }
`
export const GetRecommendedFeedV2 = gql`
query GetRecommendedFeedV2(
  $articlesIDs: [String]!,
  $carouselItemsIDs: [String]!,
  $glossaryItemsIDs: [String]!,
  $skip: Int = 0,
  $articleLimit: Int = 50,
  $locale: String = "en-US"
) {
  Read: articleCollection(
    order: [sys_id_ASC]
    skip: $skip
    locale: $locale
    limit: $articleLimit
    where: { sys: { id_in: $articlesIDs } }
  ) { ...ArticleFragment }
  Glossary: glossaryFieldCollection(
    order: [sys_id_ASC]
    skip: 0
    locale: $locale
    limit: 24
    where: {sys: {id_in: $glossaryItemsIDs}}
  ) {
    items {
      ... on GlossaryField {
        sys {
          id
        }
        term
        description
        richDescription {
          json
        }
      }
    }
  }
  Tips: carouselItemCollection(
    order: [sys_id_ASC]
    skip: 0
    locale: $locale
    limit: 24
    where: {sys: {id_in: $carouselItemsIDs}}
  ) {
    total
    skip
    limit
    items {
      ... on CarouselItem {
        __typename
        sys {
          id
        }
        title
        backgroundColor
        imageMask
        image {
          url
        }
        body {
          json
        }
        linkedFrom {
          carouselCollection {
            items {
              title
            }
          }
        }
      }
    }
  }
}
${ARTICLE_FRAGMENT}
`

export const GetSimplifiedSectionV2 = gql`
  query GetSimplifiedSectionV2(
    $articleIds: [String]!,
    $podcastIds: [String]!,
    $podcastEpisodeIds: [String]!,
    $skip: Int = 0,
    $locale: String = "en-US"
  ) {
    Article: articleCollection(
      order: [sys_id_ASC]
      skip: $skip
      where: { sys: { id_in: $articleIds } }
    ) {
      ...ArticleFragment
    }
    Podcast: podcastCollection(
      order: [sys_id_ASC]
      skip: $skip
      where: { sys: { id_in: $podcastIds } }
    ) {
      ...PodcastFragment
    }
    PodcastEpisode: podcastEpisodeCollection(
      where: { sys: { id_in: $podcastEpisodeIds } }
    ) {
      ...PodcastEpisodeFragment
    }
  }
  ${ARTICLE_FRAGMENT}
  ${PODCAST_FRAGMENT}
  ${PODCAST_EPISODE_FRAGMENT}
`
export const GetSimplifiedSection = gql`
  query GetSimplifiedSection(
    $contentTypes: [HubContentType!]
    $externalTags: [ID!]
    $userClassifications: [UserClassification!]
    $excludeEntries: [ID!]
    $requireInternalTags: [ID!]
    $excludeInternalTags: [ID!]
    $userClassificationsWeight: Int! = 0
    $skip: Int! = 0
    $limit: Int! = 4
    $locale: String = "en-US"
  ) {
    recommendedContent(
      contentTypes: $contentTypes
      externalTags: $externalTags
      userClassifications: $userClassifications
      excludeEntries: $excludeEntries
      requireInternalTags: $requireInternalTags
      excludeInternalTags: $excludeInternalTags
      userClassificationsWeight: $userClassificationsWeight
      skip: $skip
      limit: $limit
      locale: $locale
    ) {
      total
      limit
      skip
      items {
        ... on PodcastEpisode {
          __typename
          typename: __typename
          id
          sys {
            id
          }
          thumbnail {
            url
          }
          heroImage {
            imageFile {
              url
            }
            altText
          }
          description {
            json
          }
          podcastId
          url
          title
          slug
          podcast {
            sys {
              id
            }
            title
            slug
          }
        }
        ... on Article {
          __typename
          typename: __typename
          id
          sys {
            id
          }
          thumbnail {
            url
          }
          heroImage {
            imageFile {
              url
            }
            altText
          }
          url
          title
          preview
        }
        ... on DownloadableAsset {
          __typename
          typename: __typename
          sys {
            id
          }
          title
          asset {
            url
          }
          thumbnail {
            url
          }
        }
      }
    }
  }
`

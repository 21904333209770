import uniq from 'lodash/uniq'
import camelCase from 'lodash/camelCase'
import toPairs from 'lodash/toPairs'
import get from 'lodash/get'
import pickBy from 'lodash/pickBy'
import isArray from 'lodash/isArray'

const contentGraphV1: string = `${process.env.NEXT_PUBLIC_API_DOMAIN}/content-graph/v1` as string
const TAGGED_CONTENT_PATH: string = `${contentGraphV1}/hub/tagged-content`

export default async function fetchTaggedContent(
  {
    sections,
    extraInternalTags,
    externalTags,
    excludedEntries,
    userClassifications,
    userClassificationsWeight,
    skip,
    limit,
    locale,
    hardLimit = false
  }
) {
  const promises: Promise<any>[] = []
  const recommendations = []

  sections.forEach((section, index): void => {
    if (!section.type) return

    const requiredInternalTags: string[] = uniq([
      ...extraInternalTags,
      ...(section.requireInternalTags || [])
    ])

    const excludedInternalTags: string[] = uniq([
      ...(section.excludeInternalTags || [])
    ])

    // eslint-disable-next-line no-nested-ternary
    const taggedLimit = hardLimit ? limit : index === 0 ? limit * 2 : limit
    const sectionLimit = get(section, 'limit')

    const variables = {
      contentTypes: camelCase(section.type),
      externalTags: uniq(externalTags),
      excludedEntries,

      // require and/or exclude internal tags
      excludedInternalTags,
      requiredInternalTags,

      // userClassifications,
      userClassifications,
      userClassificationsWeight,

      // skip, limit, and locale
      skip,
      limit: sectionLimit || taggedLimit,
      locale
    }

    const queryParams: URLSearchParams = new URLSearchParams(
      toPairs(
        pickBy(
          variables,
          // Remove undefined values or empty arrays from variables
          (value) => !(value === undefined || (isArray(value) && value.length === 0))
        )
      )
    )

    const subPath: string = get(section, 'subPathService') || ''
    const fullUrl: string = `${TAGGED_CONTENT_PATH}${subPath}?${queryParams}`
    promises.push(
      fetch(fullUrl)
        .then((res) => res.json())
        .then((json) => ({ ...section, items: json.items, id: section.sectionId }))
        .then((result) => recommendations.push(result))
    )
  })

  await Promise.all(promises)

  return recommendations
}

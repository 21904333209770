import { gql } from '@apollo/client'

export const ARTICLE_FRAGMENT = gql`
fragment ArticleFragment on ArticleCollection {
  total
  skip
  limit
  items {
    __typename
    sys {
      id
    }
    title
    dek
    slug
    path
    locale
    articleContent {
      json
    }
    author {
      fullName
      firstName
      lastName
      title
      headshot {
        url
      }
    }
    heroImage {
      imageFile {
        url
      }
      altText
    }
    thumbnail {
      url
      description(locale: $locale)
    }
    externalTagCollection(limit: 2, locale: $locale) {
      items {
        sys {
          id
        }
        title
        slug
      }
    }
  }
}
`

export const PODCAST_EPISODE_FRAGMENT = gql`
fragment PodcastEpisodeFragment on PodcastEpisodeCollection {
  total
  limit
  skip
  items {
    typename: __typename
    sys{
      id
    }
    title
    podcastId
    thumbnail {
      title
      url
    }
    description {
      json
    }
    slug
    linkedFrom {
      podcastCollection(limit: 1) {
        items {
          typename: __typename
          sys {
            id
          }
          title
          slug
          headerImage {
            url
          }
          releaseDate
        }
      }
    }
  }
}
`

export const PODCAST_FRAGMENT = gql`
fragment PodcastFragment on PodcastCollection {
  total
  limit
  skip
  items {
    __typename
    typename: __typename
    sys {
      id
    }
    title
    slug
    headerImage {
      url
    }
    releaseDate
  }
}
`

import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import get from 'lodash/get'
import truncate from 'lodash/truncate'
import { Article, EnhancedTag, Image } from './types'

/**
 * Composes the url of the given article, using its data (slug)
 * and replaces double slashes with a single slash.
 * @param {string} slug The article object
 * @returns {String} The URL value for the article
 */
export function getArticleUrl(slug: string): string {
  return `/articles/${slug}`.replace(/\/{2,}/g, '/')
}

function getImage({ article, path }: { article: Article, path: string }): Image {
  return {
    url: get(article, `${path}.url`, null)
      || get(article, `fields.${path}.fields.file.url`, null),
    width: get(article, `fields.${path}.fields.file.details.image.width`, null),
    height: get(article, `fields.${path}.fields.file.details.image.height`, null),
    description: get(article, `fields.${path}.fields.description`, null)
  }
}

/** Given an article returns a preview text composed by the document,
 * truncated to 400 characters. The last characters of the truncated
 * string are replaced with the omission string, which defaults to "..."
 * @param {any} article The article object
 * @returns {string} The preview text truncated to 400 chars */
export function extractPreview(article: any): string {
  const content = article?.articleContent?.json || article.fields?.articleContent?.json || article.fields?.articleContent
  if (!content) return ''
  const toPlainText = documentToPlainTextString(content)
  return truncate(toPlainText, { length: 400 })
}

/** Change the shape of a list articles to match the one used by the `TrendingProvider`
 * of `@context`.
 * @param {} articles
 * @returns {Article[]} Mutated list of articles */
export function mapArticles(articles: any[]): Article[] {
  return articles.map(
    (article) => {
      const transformedArticle: Article = {
        typename: get(article, '__typename'),
        url: getArticleUrl(article.slug || get(article, 'fields.slug')),
        id: article.sys.id,
        sys: {
          id: article.sys.id
        },
        title: get(article, 'title', null)
          || get(article, 'fields.title'),
        dek: get(article, 'dek', null),
        heroImage: {
          imageFile: getImage({ article, path: 'heroImage.imageFile' }),
          altText: get(article, 'heroImage.altText', null)
            || get(article, 'fields.heroImage.fields.altText', null)
        },
        locale: get(article, 'locale', null) || get(article, 'fields.locale', null),
        thumbnail: getImage({ article, path: 'thumbnail' }),
        author: {
          fullName: get(article, 'author.fullName', null)
            || get(article, 'fields.author.fields.fullName', null),
          firstName: get(article, 'author.firstName', null)
            || get(article, 'fields.author.fields.firstName', null),
          lastName: get(article, 'author.lastName', null)
            || get(article, 'fields.author.fields.lastName', null),
          title: get(article, 'author.title', null)
            || get(article, 'fields.author.fields.title', null),
          headshot: {
            url: get(article, 'author.headshot.url', null)
              || get(article, 'fields.author.fields.headshot.fields.file.url', null)
          }
        },
        preview: extractPreview(article),
        slug: get(article, 'slug', null) || get(article, 'fields.slug', null),
        path: get(article, 'path', null) || get(article, 'fields.path', null),
        enhancedTags: get(article, 'externalTagCollection.items', get(article, 'fields.externalTag', [])).map(
          (tag: any): EnhancedTag => (
            {
              id: tag.sys.id,
              title: tag.title || get(tag, 'fields.title', null),
              slug: tag.slug || get(tag, 'fields.slug', null)
            }
          )
        )
      }
      return transformedArticle
    }
  )
}
